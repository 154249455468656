@charset "utf-8";

// BeyondCron colours
$bc-yellow: #FFAB2C;
$bc-red: #FF3F50;
$bc-purple: #BF5FC1;
$bc-blue: #008CF0;
$bc-green: #00B96F;
$bc-grey: #747474;
$bc-black: #303030;
$bc-white: #ffffff;

// Bootstrap overrides
$spacer: 1rem !default;
//$min-contrast-ratio: 2;

:root,
[data-bs-theme=light] {
  --bs-blue: $bc-blue;
  --bs-purple: $bc-purple;
  --bs-red: $bc-red;
  --bs-yellow: $bc-yellow;
  --bs-green: $bc-green;

  --bs-font-sans-serif: Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --bc-font-size-base: 1rem;
  --bs-body-font-weight: 300;
  --bc-bold-font-weight: 700;
  --bs-font-monospace: 'Ubuntu Mono', Ubuntu, Consolas, 'Liberation Mono', Courier, monospace;

  --accordion-btn-color: $bc-blue;
  --accordion-active-color: $bc-blue;
  --accordion-active-bg: $bc-white;

  --bs-warning-text-emphasis: $bc-red;
}

@import "beyondcron";
